import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Optional,
  PLATFORM_ID,
  SkipSelf
} from '@angular/core';
import {
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage
} from 'angular-oauth2-oidc';
import { authAppInitializerFactory } from '@core/services/auth/auth-app-initializer.factory';
import { AuthGuard } from '@core/services/auth/auth-guard.service';
import { authModuleConfig } from '@core/services/auth/auth-module-config';
import { AuthService } from '@core/services/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { AppErrorHandler } from '@core/services/error-handler/error-handler.service';
import { HttpCacheService } from '@core/services/http/http-cache.service';
import { UserService } from '@core/data/service/user.service';
import { IntegrationService } from '@core/data/service/integration.service';
import { PlatformResourceService } from '@core/data/service/platform-resource.service';
import { HttpCacheInterceptor } from '@core/services/http/http-cache-interceptor.service';
import { HttpErrorInterceptor } from '@core/services/http/http-error-interceptor.service';
import { NGXLogger } from 'ngx-logger';
import { HttpBaseUrlInterceptor } from '@core/services/http/http-base-url-interceptor.service';
import { HttpFieldExcluderInterceptor } from '@core/services/http/http-field-excluder-interceptor.service';
import { HttpDateConverterInterceptor } from '@core/services/http/http-date-converter-interceptor.service';
import { TitleService } from '@core/services/title.service';
import { DeveloperService } from '@core/data/service/developer.service';
import { PullRequestService } from '@core/data/service/pull-request.service';
import { IssueService } from '@core/data/service/issue.service';
import { RetrospectiveActionItemService } from '@core/data/service/retrospective-action-item.service';
import { IncidentService } from '@core/data/service/incident.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(platformId: Object): OAuthStorage | null {
  if (isPlatformBrowser(platformId)) {
    return localStorage;
  }
  return null;
}

const coreServices = [
  AuthService,
  LocalStorageService,
  HttpCacheService,
  TitleService
];

const dataServices = [
  UserService,
  IntegrationService,
  PlatformResourceService,
  DeveloperService,
  PullRequestService,
  IssueService,
  RetrospectiveActionItemService,
  IncidentService
];

const httpInterceptors = [
  {provide: HTTP_INTERCEPTORS, useClass: HttpBaseUrlInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: HttpFieldExcluderInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: HttpDateConverterInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, deps: [ErrorHandler, AuthService]},
  {provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true, deps: [HttpCacheService, NGXLogger]},
];

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  providers: [
    ...coreServices,
    ...dataServices,
    {provide: ErrorHandler, useClass: AppErrorHandler},
    ...httpInterceptors,
    AuthGuard,
    provideHttpClient(withFetch())
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory, deps: [PLATFORM_ID] }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
